//
//
import $ from 'jquery'
import debounce from 'lodash/debounce'
//
import { notifyError } from '@/services/notify'
import { ValidationError } from '@/services/errors'
//
import { DEFAULT_FORM_VALUES } from './data'
//
export const methods = {
  confirmDeleteLineItem(accountID, description, amount) {
    this.$buefy.dialog.confirm({
      title: 'Deleting G/L Line Item',
      message: `Are you sure you want to <b>delete</b>: "${description}, with amount "${amount}"?`,
      confirmText: 'Delete G/L Line Item',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteAccountAmount(accountID, amount)
    })
  },

  getGlExpenseDropDown() {
    this.$store.dispatch('accounting/getGlExpenseList').then(() => {
      if (this.glExpenseDropDownList && this.glExpenseDropDownList != undefined) {
        if (this.isDebug == true)
          console.debug('glExpenseDropDownList=' + JSON.stringify(this.glExpenseDropDownList))

        this.data = this.glExpenseDropDownList

        this.formData = { ...DEFAULT_FORM_VALUES }
        this.$refs.form.reset()
      }
    })
  },

  deleteAccountAmount(accountID, amount) {
    let counter = 0
    Array.from(this.pendingGlTransactionDetails).forEach(element => {
      if (element && element != undefined) {
        if (
          element &&
          element != undefined &&
          element.accountID == accountID &&
          element.amount == amount
        ) {
          this.pendingGlTransactionDetails.splice(counter, 1)
        }
        counter++
      }
    })
  },

  addAccountAmount() {
    if (
      !this.glFilterQuery ||
      this.glFilterQuery == undefined ||
      !this.formData ||
      this.formData.Amount == undefined
    ) {
      if (Number.isNaN(this.formData.Amount)) {
        notifyError('Please enter a numeric amount for the G/L account.')
        return
      }
      notifyError('There was a problem with adding a line item.')
      return
    }

    if (this.formData.Amount == '') {
      notifyError('There was a problem with adding a line item.  Please enter an amount.')
      return
    }

    if (this.isDebug == true)
      console.debug('account=' + this.glFilterQuery + ', amount=' + this.formData.Amount)

    var account = this.glExpenseDropDownList.filter(i => i.label.includes(this.glFilterQuery))

    if (!account || account == undefined) {
      notifyError('There was a problem with adding a line item.  Please select a G/L account.')
      return
    }

    if (account[0] && account[0].value) {
      this.pendingGlTransactionDetails.push({
        accountID: account[0].value,
        amount: this.formData.Amount,
        description: account[0].label
      })

      /*
      this.pendingGlTransactionDetails.push({
        account: account[0].label,
        amount: this.formData.Amount
      })
			*/

      if (this.isDebug == true)
        console.debug(
          'this.pendingGlTransactionDetails = ' + JSON.stringify(this.pendingGlTransactionDetails)
        )
    }
  },

  clearLineItems() {
    //clear add line item selections
    this.glFilterQuery = null
    this.formData.Amount = ''
  },

  getPayload() {
    if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

    let params = {
      postReversingEntries:
        this.formData.hasPostReversingEntriesNextPeriod &&
        this.formData.hasPostReversingEntriesNextPeriod != undefined
          ? this.formData.hasPostReversingEntriesNextPeriod
          : false,
      hoaId: this.hoaId,
      description:
        this.formData.Description && this.formData.Description != undefined
          ? this.formData.Description
          : '',
      postingDate:
        this.formData.postingDate && this.formData.postingDate != undefined
          ? this.formData.postingDate
          : null
    }

    console.log('getPayload', this.pendingGlTransactionDetails)

    //Many detail line items
    let pendingGlTransactionDetails = []
    if (this.pendingGlTransactionDetails && this.pendingGlTransactionDetails.length > 0) {
      Array.from(this.pendingGlTransactionDetails).forEach(element => {
        if (element && element != undefined) {
          if (element && element != undefined && element.accountID > 0) {
            pendingGlTransactionDetails.push({
              accountID: element.accountID,
              amount: element.amount,
              description: element.description
            })
          }
        }
      })
    }
    params.pendingGlTransactionDetails = pendingGlTransactionDetails

    return params
  },
  async onFormSubmit() {
    if (
      !this.formData ||
      this.formData == undefined ||
      !this.pendingGlTransactionDetails ||
      this.pendingGlTransactionDetails.length == 0
    ) {
      notifyError('There was a problem submitting your Stage G/L Post.')
      return
    }

    if (!this.formData.postingDate) {
      notifyError('Posting Date is required.')
      return
    }

    this.loading = true

    try {
      //Add
      const payload = this.getPayload()

      if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

      this.loading = true
      this.$store.dispatch('accounting/addBatchProcessPendingPostToGl', {
        postReversingEntries: payload.postReversingEntries,
        hoaId: payload.hoaId,
        description: payload.description,
        postingDate: payload.postingDate,
        pendingGlTransactionDetails: payload.pendingGlTransactionDetails,
        done: () => {
          // auto-close modal
          this.$emit('update:toggle', (this.toggle = false))
          $('#clickclose').click()
          this.loading = false
        }
      })
    } catch (e) {
      // If this is a validation error, get the details for each field
      if (e instanceof ValidationError) {
        this.$refs.form.setErrors(e.fields)
      }

      notifyError('There was a problem creating the pending G/L transactions.')
    }
    this.loading = false
  },
  getGlAsyncData: debounce(function(name) {
    // String update
    if (this.name !== name) {
      this.name = name
      this.data = []
    }

    // String cleared
    if (!name.length) {
      this.data = this.glExpenseDropDownList
      return
    }

    this.isFetching = true
    if (this.isDebug == true) console.debug('this.name=' + this.name)

    if (this.glExpenseDropDownList) {
      this.data = this.glExpenseDropDownList.filter(i =>
        i.label.toLowerCase().includes(this.name.toLowerCase())
      )
    }
    this.isFetching = false
  }, 500),
  getMoreGlAsyncData: debounce(function() {
    this.getGlAsyncData(this.name)
  }, 250)
}

import { DEFAULT_FORM_VALUES } from './data'

export function mounted() {
  this.loading = true
  this.$store.dispatch('accounting/getExpenseCategoriesList')

  this.pendingGlTransactionDetails = [{}]

  if (this.isDebug == true)
    console.debug(
      'mounted pendingGlTransactionHeader =' + JSON.stringify(this.pendingGlTransactionHeader)
    )

  if (this.pendingGlTransactionHeader) {
    this.formData.postingDate = new Date(this.pendingGlTransactionHeader.transactionDate.toString())

    this.formData.Description = this.pendingGlTransactionHeader.description
    this.formData.hasPostReversingEntriesNextPeriod = this.pendingGlTransactionHeader.postReversingEntries

    this.buttonText = 'View'

    this.pendingGlTransactionDetails = []

    if (
      this.pendingGlTransactionHeader.pendingGlTransactionDetails &&
      this.pendingGlTransactionHeader.pendingGlTransactionDetails.length > 0
    ) {
      Array.from(this.pendingGlTransactionHeader.pendingGlTransactionDetails).forEach(element => {
        if (element && element != undefined) {
          this.pendingGlTransactionDetails.push({
            accountID: element.accountID,
            amount: element.amount,
            description:
              element.account && element.account.description ? element.account.description : ''
          })
        }
      })
    }
  } else {
    this.formData = { ...DEFAULT_FORM_VALUES }
    this.$refs.form.reset()
    this.getGlExpenseDropDown()
    this.buttonText = 'Stage Post'
  }

  this.loading = false
}

export const watch = {
  hoaId() {
    this.reload()
  },

  toggle() {
    if (this.toggle == false) {
      this.reload()
      this.toggle = false
      if (this.Modal && this.Modal != undefined) {
        this.Modal.$emit('update:toggle', (this.toggle = false))
      }
    }
  }
}

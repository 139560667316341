<template>
  <PageContentLayoutOnly>
    <ModernTable
      :filters="filters"
      :rows="pendingGlTransactionHeaders"
      :columns="columns"
      :pagination="pagination"
    >
      <template v-slot:header>
        <h3 style="display: inline-block">{{ $t('pendingPostToGl.title') }}</h3>
        <div class="p-2">
          <b-button
            type="is-ghost"
            @click="toggleFilters"
            :style="styles.filterButton"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
        <nav class="level" :style="{ paddingBottom: '20px' }">
          <div class="level-left">
            <b-button @click="createPendingHeaderModal()" size="is-small" type="is-primary" rounded>
              Stage Post to G/L
            </b-button>
          </div>
        </nav>
      </template>
    </ModernTable>

    <Modal :toggle.sync="toggle" :h_width.sync="customModalWidth">
      <StagePosting
        :h_width="customModalWidth"
        :toggle="toggle"
        :pendingGlTransactionHeader="selectedPendingHeader"
      />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import Modal from '@/components/Modal'
import StagePosting from '@/components/Accounting/StagePosting'

import { watch } from './keys/watch'
import { methods } from './keys/methods'
import { data } from './keys/data'
import { computed } from './keys/computed'

export default {
  name: 'PendingGlTransactions',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    Modal,
    StagePosting
  },

  watch,
  methods,
  data,
  computed,

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { pendingPostToGl: { title: 'G/L Transactions Pending Posting' } }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

@media screen and (max-width: $tablet) {
  .filter-button {
    display: none;
  }
}

div.amountFrom {
  width: 60px !important;
}

.filter-button {
  color: #838383 !important;

  &.active {
    color: $link !important;
  }
}

.button.is-ghost {
  background: none;
  border-color: transparent;
  color: #7957d5;
  text-decoration: none;
}
</style>

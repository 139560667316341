//
import { parseDate } from '@/utilities/Date/parse'
import Button from '@/components/buttons/Button'
//
import { mapActions } from 'vuex'
import _get from 'lodash/get'
//
import { notifyError } from '@/services/notify'

export const methods = {
  ...mapActions('accounting', ['getPendingGlTransactionsHeaderList']),

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  confirmDeletePendingPosting(id, name) {
    this.$buefy.dialog.confirm({
      title: 'Delete Pending Posting',
      message: `Are you sure you want to <b>delete</b> the G/L Pending Posting for "${name}"?`,
      confirmText: 'Delete G/L Transaction Pending Posting',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteTransactionPendingPosting(id)
    })
  },

  determinePendingHeaderRows() {
    if (this.isDebug == true) console.debug('determinePendingHeaderRows()...')

    if (this.pendingGlTransactionHeaderList) {
      this.pendingGlTransactionHeaders = this.pendingGlTransactionHeaderList.map(entry => {
        const dateFormat = 'LL'

        const tempDate = _get(entry, 'transactionDate', '')
        const transactionDate = parseDate(tempDate, dateFormat)
        const id = _get(entry, 'pendingGlTransactionHeaderID', '')
        const description = _get(entry, 'description', '')

        return {
          description: description,
          transactionDate: transactionDate,
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processPendingUpdate(entry)
              },
              text: 'View'
            }
          },
          erase: {
            component: Button,
            props: {
              onClick: () => {
                this.confirmDeletePendingPosting(id, description)
              },
              text: 'Delete'
            }
          }
        }
      })
    } else {
      console.log('else determinePendingHeaderRows...')
    }
  },

  async reload() {
    this.loading = true

    try {
      this.$store.dispatch('accounting/getPendingGlTransactionsHeaderList').then(() => {
        this.determinePendingHeaderRows()
      })
    } catch (e) {
      console.debug('error in method for retrieving chart of accounts:' + e)
    }

    this.loading = false
  },

  createPendingHeaderModal() {
    if (this.isDebug == true) console.debug('in createPendingHeaderModal...')
    this.selectedPendingHeader = null
    this.toggle = true
  },

  processPendingUpdate(pendingHeader) {
    this.toggle = true
    this.selectedPendingHeader = pendingHeader
  },

  async deleteTransactionPendingPosting(id) {
    if (this.isDebug == true) console.debug('...in deleteTransactionPendingPosting id=' + id)

    if (id <= 0) {
      notifyError('There was a problem deleting your G/L transaction pending posting.')
      return
    }

    this.$store.dispatch('accounting/deletePendingGlTransaction', {
      pendingGlTransactionHeaderID: id,
      done: () => {
        this.loading = false
        this.reload()
      }
    })

    this.loading = false
  }
}

import { mapState, mapGetters } from 'vuex'

export const computed = {
  ...mapState({
    hoaId: state => state.user.selectedHoaId
  }),
  ...mapGetters('accounting', ['glExpenseDropDownList']),
  glExpenseFilterSuggestions() {
    if (this.glFilterQuery && this.glFilterQuery && this.glFilterQuery != undefined) {
      return this.glExpenseDropDownList.filter(
        a => a.label.indexOf(this.glFilterQuery.toLowerCase()) !== -1
      )
    } else {
      return this.glExpenseDropDownList
    }
  },
  summedDebits() {
    return this.pendingGlTransactionDetails.reduce(
      (acc, item) => (item.amount > 0 ? acc + item.amount * 1 : acc),
      0
    )
  },
  summedCredits() {
    return this.pendingGlTransactionDetails.reduce(
      (acc, item) => (item.amount < 0 ? acc + item.amount * -1 : acc),
      0
    )
  },
  isBalanced() {
    return this.summedDebits - this.summedCredits == 0 ? true : false
  }
}

var field = {
  width: '100px',
  display: 'inline-block',
  paddingRight: '6px',
  textAlign: 'left'
}

export const data = () => ({
  isDebug: true,
  toggle: false,
  loading: false,
  customModalWidth: '50%',
  pendingGlTransactionHeaders: [],
  selectedPendingHeader: null,
  filters: {
    show: true
  },

  pagination: {
    perPage: 10
  },

  columns: [
    {
      field: 'description',
      label: 'Description',
      aria: 'Description',
      width: '60%',
      sortable: true,
      searchable: true
    },
    {
      field: 'transactionDate',
      label: 'Transaction Date',
      width: '20%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    formRow: {
      padding: '10px',
      whiteSpace: 'nowrap'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    },
    amountField: Object.assign({}, field, {
      width: '125px'
    })
  }
})

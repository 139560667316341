<template>
  <div id="accounting--stage-posting">
    <div class="is-size-4" v-if="buttonText == 'View'">
      View G/L Transaction Pending Posting
    </div>
    <div class="is-size-4" v-else>Stage Post to G/L</div>

    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
          <div class="tile is-horizontal is-12" :style="{ flexDirection: 'column' }">
            <fieldset :style="{ width: '100%' }">
              <div class="tile is-ancestor">
                <div class="tile is-vertical is-parent">
                  <article class="tile is-child notification rounded-tile">
                    <div class="column is-12" :style="styles.formRow">
                      <b-field label="Posting Date">
                        <b-datepicker
                          v-model="formData.postingDate"
                          label="Posting Date"
                          rules="required"
                          aria-required="Posting Date is required"
                          :show-week-number="showWeekNumber"
                          placeholder="Click to select Posting Date..."
                          icon="calendar-today"
                          trap-focus
                        ></b-datepicker>
                      </b-field>
                    </div>
                    <div class="column is-12">
                      <valid-input
                        style="width:50px !important;"
                        name="Description"
                        label="Description"
                        maxlength="100"
                        type="text"
                        vid="description"
                        placeholder="Description"
                        spellcheck="true"
                        aria-label="Description"
                        class="is-small"
                        rules="max:100|required"
                        v-model="formData.Description"
                      />
                    </div>
                    <div class="column is-12">
                      <b-checkbox
                        class="has-left-text"
                        v-model="formData.hasPostReversingEntriesNextPeriod"
                        >Post Reversing Entries at Beginning of Next Period</b-checkbox
                      >
                    </div>
                  </article>
                  <article class="tile is-child notification rounded-tile">
                    <div class="pt-6 column is-12 cursor-pointer">
                      <div v-if="buttonText != 'View'" class="columns is-12">
                        <div class="column is-6">
                          <b-field label="G/L Account">
                            <b-autocomplete
                              size="is-small"
                              field="label"
                              icon="magnify"
                              :loading="isFetching"
                              placeholder="e.g. G/L Account Search"
                              :data="data"
                              :open-on-focus="openOnFocus"
                              v-model="glFilterQuery"
                              @typing="getGlAsyncData"
                              @select="option => (selected = option)"
                            >
                            </b-autocomplete>
                          </b-field>
                        </div>
                        <div class="column is-3">
                          <valid-input
                            style="width:50px !important;"
                            name="Amount"
                            label="Amount"
                            maxlength="10"
                            type="text"
                            vid="amount"
                            placeholder="Amount"
                            spellcheck="true"
                            aria-label="Amount"
                            class="is-small"
                            rules="max:10"
                            v-model="formData.Amount"
                          />
                        </div>
                        <div class="column is-1" style="padding-top:37px;">
                          <b-button class="is-primary is-light" @click="addAccountAmount()"
                            >Add</b-button
                          >
                        </div>
                      </div>

                      <ModernTable
                        :columns="[
                          {
                            field: 'accountID',
                            label: 'Account',
                            numeric: true,
                            sortable: true,
                            searchable: true
                          },
                          {
                            field: 'amount',
                            label: 'Amount',
                            sortable: true,
                            searchable: true
                          }
                          // description
                        ]"
                        :rows="pendingGlTransactionDetails"
                        :filters="{
                          show: true
                        }"
                        :loading="loading"
                      />
                    </div>
                  </article>
                </div>
              </div>
              <div
                class="pt-5 box"
                style="background-color: #f8f9fa;"
                v-if="pendingGlTransactionDetails && pendingGlTransactionDetails != undefined"
              >
                <div class="columns">
                  <div class="font-bold column is-three-fifths">
                    <span class="redcurrency" v-if="!isBalanced">Must Balance to Zero</span>
                  </div>
                  <div class="font-bold column is-one-fifth">Total Debits:</div>
                  <div class="column is-one-fifth">{{ summedDebits | currency }}</div>
                </div>
                <div class="columns">
                  <div class="font-bold column is-three-fifths"></div>
                  <div class="font-bold column is-one-fifth">Total Credits:</div>
                  <div class="column is-one-fifth" style="text-decoration: underline">
                    -{{ summedCredits | currency }}
                  </div>
                </div>
                <div class="columns">
                  <div class="font-bold column is-three-fifths"></div>
                  <div class="font-bold column is-one-fifth">Total:</div>
                  <div class="font-bold column is-one-fifth">
                    {{ (summedDebits - summedCredits) | currency }}
                  </div>
                </div>
              </div>
              <div v-if="buttonText != 'View'" class="pb-5">
                <button
                  type="submit"
                  :disabled="loading || !isBalanced || summedDebits == 0"
                  class="button is-primary is-rounded"
                >
                  {{ buttonText }}
                </button>
              </div>
            </fieldset>
            <fieldset :style="{ width: '100%' }">
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </div>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
//
import { ValidationObserver, ValidationProvider } from 'vee-validate'
//
//
import ValidInput from '@/components/inputs/ValidInput'
//
//
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { watch } from './keys/watch'
//
import ModernTable from '@/components/tables/Modern/Table'
//
export default {
  name: 'StagePostToGlModal',

  props: {
    pendingGlTransactionHeaderID: Number,
    pendingGlTransactionHeader: Object
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    ModernTable
  },

  computed,
  data,
  watch,

  created() {
    //clear state from previous staging
    this.pendingGlTransactionDetails = [{}]
  },

  methods,
  mounted
}
</script>

<style lang="scss">
#accounting--stage-posting {
  .step-details {
    background-color: #fff !important;
    padding-left: 0.25rem !important;
    padding-right: 0.5rem !important;
  }

  .b-steps.is-small .steps {
    margin-top: 1rem;
  }

  .b-steps .steps + .step-content {
    padding-top: 0 !important;
  }

  .step-navigation {
    display: flex;
    justify-content: flex-end;
  }

  .my-grid-class {
    height: 400px;
  }

  tbody {
    min-height: 100px !important;
    overflow: auto !important;
  }
}
</style>

const DEFAULT_FORM_VALUES = Object.freeze({
  postingDate: null,
  description: '',
  hasPostReversingEntriesNextPeriod: false,
  amount: 0
})

const data = function() {
  return {
    formComplete: false,
    formData: {
      ...DEFAULT_FORM_VALUES
    },
    loading: false,
    isDebug: true,
    isFetching: false,
    openOnFocus: true,
    selectedRow: null,
    buttonText: 'Stage Post',
    hasPostReversingEntriesNextPeriod: false,
    glFilterQuery: null,
    data: [],

    //
    /*
      { accountID, amount, description }
		*/
    pendingGlTransactionDetails: [],
    showWeekNumber: false,
    styles: {
      formRow: {
        padding: '10px',
        whiteSpace: 'nowrap'
      }
    }
  }
}

export { DEFAULT_FORM_VALUES, data }
